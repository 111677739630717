import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/DocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Login Screen`}</h1>
    <p>{`The login screen allows for login through Google or Apple.
There is also guest mode for those who do not want a login.
Lastly, the bottom provides links about the app.`}</p>
    <p><strong parentName="p">{` The login page will be skipped if previously login `}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2dd69565cdc64245ff8da924fa9e8f16/ec605/login_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "203.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAApABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIEAwUB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAEDAv/aAAwDAQACEAMQAAABpXqFoRHQM6kzrVpzQlUXyYdoAf/EAB0QAAIDAAIDAAAAAAAAAAAAAAIDAAESEDIRIzH/2gAIAQEAAQUCKlgXrgpAxv7KjLZvTovzhh4G2ZviuD6r7z//xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIQE2H/2gAIAQMBAT8BxMojXRX/AP/EABkRAAMAAwAAAAAAAAAAAAAAAAABEwIgYf/aAAgBAgEBPwGpTIT5p//EAB8QAAEDBAMBAAAAAAAAAAAAAAEAEBECEjJBICEiof/aAAgBAQAGPwK3tb+K4EtpvNMhYIXCCpVIjLiX/8QAHhAAAgEEAwEAAAAAAAAAAAAAAAEREDFhcSFBoZH/2gAIAQEAAT8heGm1o1JaQp6MPhGBYJsk9DMH4S6AETFPMCpQ6HYlPNPEWqf/2gAMAwEAAgADAAAAEIvWTzAP/8QAGhEBAAMAAwAAAAAAAAAAAAAAAQARIRCR4f/aAAgBAwEBPxB1idQp02FbbPYKK5//xAAZEQEAAwEBAAAAAAAAAAAAAAABABEhEJH/2gAIAQIBAT8QM6PsbsY1FGLbff/EACIQAAICAQQBBQAAAAAAAAAAAAERACExEEFxkWGhweHw8f/aAAgBAQABPxCk6WVvveNDE9AtEAkVFdgSVlhF+MQENAcYgdZTA3XMNSJ194ZINzEULg0a9A++IR9QiAk1nvR2WVCPMlv+a96A6f/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Login button",
            "title": "Login button",
            "src": "/static/2dd69565cdc64245ff8da924fa9e8f16/09b79/login_screen.jpg",
            "srcSet": ["/static/2dd69565cdc64245ff8da924fa9e8f16/59e87/login_screen.jpg 60w", "/static/2dd69565cdc64245ff8da924fa9e8f16/e439a/login_screen.jpg 120w", "/static/2dd69565cdc64245ff8da924fa9e8f16/09b79/login_screen.jpg 240w", "/static/2dd69565cdc64245ff8da924fa9e8f16/158ba/login_screen.jpg 360w", "/static/2dd69565cdc64245ff8da924fa9e8f16/7cc5e/login_screen.jpg 480w", "/static/2dd69565cdc64245ff8da924fa9e8f16/ec605/login_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Continue offline`}</h3>
    <p>{`Continue as guest for Response Time Track. Preferred if want to stay private and keep data on phone.`}</p>
    <h3>{`Login with Apple`}</h3>
    <p>{`Login with Apple for Response Time Track.
Apple may ask if you want your email shared, which is optional.
If not paid for subscription, there is no cloud storage.`}</p>
    <h3>{`Login with Google`}</h3>
    <p>{`Login with Google for Response Time Track.
If not paid for subscription, there is no cloud storage.`}</p>
    <h3>{`Login with Email`}</h3>
    <p>{`Login with your email for Response Time Track.
If not paid for subscription, there is no cloud storage.`}</p>
    <h4>{`Forgot Password`}</h4>
    <p>{`Forgot password is for those who forgot password, which an email will be sent for forgot password.`}</p>
    <h4>{`Sign up`}</h4>
    <p>{`Sign up is required for email address login.
Only email and password is needed.
After doing email registration, the account must be verified in email to confirm the email is legit.`}</p>
    <h3>{`Links`}</h3>
    <p>{`Below links are for release notes, documentation, and privacy policy`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      